AT.utils = AT.utils || {};

AT.utils.tnew = (function () {
	function init() {
		$('td[style*="background-color"]').css({
			'background': 'none',
			'background-color': 'transparent'
		});
	}

	return {
		init: init
	};
}());