(function ($) {
	$.fn.parallax = function (_opts) {
		var $points = {};
		var defaultOpts = {
			interval: 400,
			percent: 0.85,
			topOffset: 0.00,
			bottomOffset: 0.00,
			buffer: 100,
			nowrap: false,
			debug: false
		};

		var opts = $.extend(true, defaultOpts, _opts);

		$points = $(this).length > 0 ? ($points.length > 0 ? $.extend(true, $points, $(this)) : $(this)) : {};

		function init() {
			if (typeof $points !== 'undefined' && $points && $points.length > 0) {
				$points.each(function () {
					var $t = $(this);
					if (!$t.parent().hasClass('parallax-outer') && !opts.nowrap) {
						$t.wrap('<div class="parallax-outer clearfix"></div>');
					}
					if ($t.find('[class*="quad-"]').length > 0) {
						$t.addClass('parallax-quad');
					}
				});
				if (opts.debug) {
					$('body').append('<div id="parallax-crosspoint"></div>');
					$('#parallax-crosspoint').css({
						position: 'fixed',
						borderTop: '1px solid #0f0',
						top: (opts.percent * 100) + '%',
						left: 0,
						width: '100%',
						height: 0
					});
				}
			}

			updateParallax();

			$(window).off('load.at.parallax', updateParallax).on('load.at.parallax', updateParallax);
		}

		function updateParallax() {
			var scrollTop;
			var innerHeight;
			var crossPointBottom;
			var crossPointTop;

			if ($points && $points.length > 0) {
				scrollTop = $(window).scrollTop();
				innerHeight = $(window).height();
				crossPointBottom = (scrollTop + (innerHeight * opts.percent)) + (innerHeight * opts.bottomOffset);
				crossPointTop = scrollTop + ((1 - opts.percent) * innerHeight) + (innerHeight * opts.topOffset);

				$points.each(function () {
					var $t = $(this);
					var thisTop;
					var thisHeight;
					var thisBottom;
					var $imgs;
					var p;
					var $translatable;
					var $proxy = $t;

					if (!$t.hasClass('parallax-set')) {
						setTimeout(function () {
							$t.addClass('parallax-set');
						}, 333);
					}

					// I'm not sure about this yet
					// if ($t.parent().hasClass('parallax-outer')) {
					// 	$proxy = $t.parent();
					// }

					if ($t.is(':visible')) {
						thisTop = $proxy.offset().top;
						thisHeight = $t.outerHeight();
						thisBottom = thisTop + thisHeight;

						$imgs = $t.find('img[data-src]');

						if ($imgs.length > 0 && thisTop <= (scrollTop + innerHeight + opts.buffer) && thisBottom >= (scrollTop - opts.buffer)) {
							$imgs.each(function () {
								var $img = $(this);
								if ($img.attr('src') !== $img.data('src')) {
									$img.attr('src', $img.data('src'));
								}
								$img.data('src', null).removeAttr('data-src');
							});
						}

						if (!$('html').hasClass('touchevents')) {
							$t.not('.parallax-init').addClass('parallax-init');

							if (thisTop > crossPointBottom) {
								$t.removeClass('parallax-on parallax-above').addClass('parallax-below').not('.parallax-was-on').addClass('parallax-off');
							} else {
								if (thisBottom >= crossPointTop) {
									$t.removeClass('parallax-off parallax-above parallax-below').addClass('parallax-on parallax-was-on');
								} else {
									$t.removeClass('parallax-on parallax-below').addClass('parallax-above').not('.parallax-was-on').addClass('parallax-off');
								}
								if (thisTop < crossPointTop) {
									$t.addClass('parallax-above');
								}
							}

							p = (crossPointBottom - thisTop) / thisHeight;
							p = p < 0 ? 0 : (p > 1 ? 1 : p);

							$translatable = $t.find('[data-parallax-translate]');

							$translatable.each(function () {
								var $item = $(this);
								var translateData = $item.data('parallax-translate');
								var opacityPercent = p;
								var translates;
								var i;
								var object;

								translateData = translateData ? new Array(translateData) : null;

								if (translateData && translateData.length > 0) {
									translates = '';

									for (i = 0; i < translateData[0].length; i++) {
										object = translateData[0][i];

										switch (object.property) {
											case 'opacity':
												opacityPercent = (object.start + (object.end - object.start) * p);
												break;
											default:
												translates += object.property + '(' + (object.start + (object.end - object.start) * p) + object.unit + ') ';
												break;
										}
									}

									$item.css({
										transform: translates,
										opacity: opacityPercent
									});
								}
							});
						}
					}
				});
			}
		}

		init();

		function parallaxThrottle(fn, threshhold, scope) {
			var last;
			var deferTimer;

			threshhold = threshhold || 250;

			return function () {
				var context = scope || this;
				var now = 0 + new Date();
				var args = arguments;

				if (last && now < last + threshhold) {
					clearTimeout(deferTimer);

					deferTimer = setTimeout(function () {
						last = now;
						fn.apply(context, args);
					}, threshhold);
				} else {
					last = now;
					fn.apply(context, args);
				}
			};
		}

		if (!$('html').hasClass('touchevents')) {
			$(window).on('scroll', parallaxThrottle(updateParallax, opts.interval)).on('resize', parallaxThrottle(updateParallax, opts.interval));
		}

		return $(this);
	};
}(jQuery));