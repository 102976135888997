AT.components.search = (function () {
    function init() {
        $(document).ready(function () {

            var $searchBox = ('.search-box');
            var baseUrl = ($('.wrapper .top-search').length) ? $('.wrapper .top-search').attr('base-url') : ''; 

            // Reset
            $('#SearchResults').removeClass('hidden');
            $('#SearchResults').slideUp(0);
            $('.nav-menu .top .search-exit').removeClass('hidden');
            $('.nav-menu .top .search-exit').slideUp(0);

            // Top Nav
            $('.top-search .search-box').on('keypress', function (e) {
                if (e.which == 13) {
                    searchRedirect(baseUrl);
                    return false;
                }
            });

            $('.top-search .search-btn, .header .search-btn').on('click', function (e) {
                searchRedirect(baseUrl);
            });

            // Search page
            $('.search-component').not('.init').each(function () {
                var s;
                var $this = $(this);
                var $searchBox = $this.find('.search-box');
                var $searchIcon = $this.find('.search-icon');
                var $searchResults = $this.find('.search-results');
                var $searchCount = $this.attr('.search-count');
                var $apiKey = $this.attr('api-key');
                var $searchEngineId = $this.attr('search-engine-id');
                var $query = $this.attr('query');

                s = {
                    $searchComponent: $this,
                    $searchBox: $searchBox,
                    $searchIcon: $searchIcon,
                    $searchResults: $searchResults,
                    $searchCount: $searchCount,
                    $apiKey: $apiKey,
                    $searchEngineId: $searchEngineId,
                    $query: $query
                };

                $searchBox.on('keypress', function (e) {
                    if (e.which == 13) {
                        googleCustomSearch(s);
                        return false;
                    }
                });

                $searchIcon.on('click', function (e) {
                    googleCustomSearch(s);
                });

                if ($query) {
                    $searchBox.val($query);
                    googleCustomSearch(s);
                }
            });

            function searchRedirect(baseUrl) {
                var q = ($('.search-cont .search-box').val()) ? $('.search-cont .search-box').val() : '';
                searchLocation = baseUrl + '/search?query=' + q;
                location.href = searchLocation;
            }

            function googleCustomSearch(searchComponent) {
                var searchText = searchComponent.$searchBox.val();

                $.ajax({
                    url: "https://www.googleapis.com/customsearch/v1?key=" + searchComponent.$apiKey + "&cx=" + searchComponent.$searchEngineId + "&q=" + searchText,
                    context: document.body,
                    success: function (searchResults) {

                        searchComponent.$searchResults.empty();
                        $.each(searchResults.items.slice(0, searchComponent.$searchCount), function (index, value) {
                            appendSearchResult(value, searchComponent.$searchResults);
                        });
                        //$('.search-component .search-results').slideDown(500);
                        //$('input[type="submit"].search-icon').slideUp(200);
                        //$('.nav-menu .top .search-exit').slideDown(200);
                    }
                });
            }

            function appendSearchDescription(anchor) {
                var elem = $($('#Search_ResultDescription').html());
                elem.find('.search-spelling').prepend("Showing results for")
                elem.find('.search-spelling a').attr('href', "")
                elem.find('.search-spelling-original').prepend("Search instead for ")
                elem.find('.search-spelling-original a').attr('href', "")
                elem.appendTo($(anchor));
            }
            function appendSearchResult(searchResult, anchor) {
                var elem = $($('#Search_WebResult').html())
                elem.find('.search-title a').text(searchResult.title)
                elem.find('.search-title a').attr('href', searchResult.link)
                elem.find('.search-snippet').prepend(searchResult.snippet)
                //elem.find('.search-url').text(searchResult.link)
                elem.appendTo(anchor);
            }

            $('.mobile-top .close').click(function () {
                if ($(window).width() < tablet_width) {
                    $('.sub-menu').removeClass('open');
                    $('.sub-menu .mobile-fade').removeClass('fadeIn');
                    $('body, html').removeClass('menu-open');

                    if ($('.search-link').hasClass('open')) {
                        $('.top-search').removeClass('open');
                        $('.hero').removeClass('top-slide');
                        $('.sub-menu').removeClass('mob-search');
                        $('.top-search').slideUp(500);
                        $('.search-link').removeClass('open');
                        $('header').removeClass('nav-open');
                    }
                }
            });
            if ($('.search-link').length) {
                $('.search-link').click(function () {
                    if ($(this).hasClass('open')) {
                        $('.top-search').removeClass('open');
                        $('.hero').removeClass('top-slide');
                        $('.sub-menu').removeClass('mob-search');
                        $('.top-search').slideUp(500);
                        $(this).removeClass('open');
                        $('header').removeClass('nav-open');
                        $('.header-match-height').removeClass('top-slide');
                        $('.header-match-height').addClass('sliding')
                        return;
                    }


                    $(this).addClass('open');
                    $('.sub-menu').addClass('mob-search');
                    $('.top-search').addClass('open');
                    $('header').addClass('nav-open');
                    $('.hero').addClass('top-slide');
                    $('.header-match-height').addClass('top-slide');
                    $('.header-match-height').removeClass('sliding');
                    $('.top-search').slideDown(400);
                });
            }
        });
    }
    return {
        init: init
    };
}());