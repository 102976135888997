AT.components.donateBar = (function () {

	function init() {
		$('.donate-bar').each(function () {
			var $this = $(this);

			var $select = $this.find('.donate-bar--fund-select');
			var $field = $this.find('.donate-bar--dollar-field');
			var $button = $this.find('.donate-bar--donate-btn');

			var selectID = 'select-' + window.guid();
			var fieldID = 'field-' + window.guid();

			var oY = $this.offset().top;

			var $buffer = $('<div class="buffer"></div>').height($this.innerHeight());

			$this.after($buffer);

			$select.attr('id', selectID).prev('label').attr('for', selectID);
			$field.attr('id', fieldID).on('keyup', function (e) {
				var $this = $(this);
				var v = $this.val();

				// if (v !== '$') {
				// 	v = v.replace(/[^\d\.]+/g, '').replace(/^0+/g, '');

				// 	if (!isNaN(v) && !v.match(/\.0?0?$/)) {
				// 		v = Math.floor(v * 100) / 100;
				// 	}

				// 	v = (!!v) ? '$' + v : '';
				// }

				$this.val(v);

				if (e.keyCode === 13) {
					$button.trigger('click');
				}
			}).prev('label').attr('for', fieldID);

			$button.on('click', function (e) {
				e.preventDefault();
			});

			$(window).on('scroll', AT.utils.throttle(function () {
				$buffer.height($this.innerHeight());
				$this.toggleClass('pinned', $(window).scrollTop() >= oY);
			}, 10)).on('load', function () {
				$(this).trigger('scroll');
			}).on('resize', AT.utils.debounce(function () {
				if ($this.hasClass('pinned')) {
					oY = $buffer.height($this.innerHeight()).offset().top;
				} else {
					oY = $this.offset().top;
				}

				$(this).trigger('scroll');
			}, 100));
		});
	}

	return {
		init: init
	};
}());