AT.components.donors = (function () {

	function init() {
		$('.donors').each(function () {
			var $this = $(this);
			var current = $this.data('default') || 0;
            var currentIndex = $this.data('index') || 0;

			var $donorsOptions = $this.find('.donors--options');
			var $donorsOptionsWrapper = $this.find('.donors--options--wrapper');
			var $donorsList = $this.find('.donors--list');
			var $slides = $donorsList.find('li');
			var sliderIndex = 0;
			var lastIndex = 0;
			var $prevBtn = $('<button type="button" class="btn prev-btn"><span class="larr"></span></button>').appendTo($donorsOptionsWrapper);
			var $nextBtn = $('<button type="button" class="btn next-btn"><span class="rarr"></span></button>').appendTo($donorsOptionsWrapper);
			var slideWidth = $slides.first().width() + 20;

			var $radios = $this.find('.donors--level--radio');
			var _group = 'radio-' + window.guid();

			var $benefits = $this.find('.donors--benefits--item');

			var $details = $this.find('.donors--details--description');
			var $detailsContainer = $this.find('.donors--details');
			var _detailsID = 'details-' + window.guid();

			$detailsContainer.attr('id', _detailsID).attr('aria-live', 'polite');

			$(window).on('resize', AT.utils.debounce(function () {
				slideWidth = $slides.first().width() + 20;

				_checkSlider();
			}, 100)).on('load', function () {
				$(this).trigger('resize');
			});

			$(document).ready(function () {
				$(window).trigger('resize');
			});

			$donorsOptions.on('scroll', function () {
				$donorsOptions.scrollTop(0).scrollLeft(0);
			});

			$radios.attr('name', _group).attr('aria-controls', _detailsID).each(function (i) {
				var $radio = $(this);
				var _id = 'radio-' + window.guid();

				$radio.attr('id', _id).data('i', i).closest('label').attr('for', _id);
            }).on('change', function () {
                currentIndex = $(this).data('index');
                _select($(this).data('amount'));
			});

			$prevBtn.on('click', function (e) {
				e.preventDefault();

				_slideTo(sliderIndex - 1);
			});

			$nextBtn.on('click', function (e) {
				e.preventDefault();

				_slideTo(sliderIndex + 1);
			});

			_select(current);

			function _checkSlider() {
				var _slidesPerScreen = Math.floor(($donorsOptionsWrapper.width() - 60) / ($donorsList.find('li').first().width() + 20));
				lastIndex = $radios.length - _slidesPerScreen;

				$donorsOptions.toggleClass('slidable', $donorsList.width() > $donorsOptionsWrapper.width())
					.css('padding-left', (($donorsOptionsWrapper.width() - 60 - _slidesPerScreen * slideWidth + 20) / 2) + 'px');

				_slideTo(sliderIndex);
			}

			function _slideTo(which) {
				sliderIndex = which;

				if (sliderIndex >= lastIndex) {
					sliderIndex = lastIndex;
				}

				if (sliderIndex <= 0) {
					sliderIndex = 0;
				}

				$donorsList.css('left', (slideWidth * sliderIndex * -1) + 'px');

				_checkSliderPosition();
			}

			function _checkSliderPosition() {
				$donorsOptions.toggleClass('start', sliderIndex === 0).toggleClass('end', sliderIndex === lastIndex);
			}

			function _select(which) {
				current = which;
                $radios.eq(currentIndex).prop('checked', true);
                $details.addClass('hide').eq(currentIndex).removeClass('hide');

				$benefits.each(function () {
					var $benefit = $(this);

					$benefit.toggleClass('checked', current >= $benefit.data('level'));
				});
            }
            var addRule = (function (style) {
                var sheet = document.head.appendChild(style).sheet;
                return function (selector, css) {
                    var propText = Object.keys(css).map(function (p) {
                        return p + ":" + css[p]
                    }).join(";");
                    sheet.insertRule(selector + "{" + propText + "}", sheet.cssRules.length);
                }
            })(document.createElement("style"));

            addRule('.donors[data-color="' + $this.data('color') + '"] .donors--level .donors--level--radio::after', {
                background: $this.data('color')
            });
            addRule('.donors[data-color="' + $this.data('color') + '"] .donors--benefits--item.checked  .donors--benefits--item--check', {
                background: $this.data('color')
            });
            addRule('.donors[data-color="' + $this.data('color') + '"] .donors--options ~ .next-btn', {
                background: $this.data('color')
            });
            addRule('.donors[data-color="' + $this.data('color') + '"] .donors--options ~ .prev-btn', {
                background: $this.data('color')
            });
            
		});
	}

	return {
		init: init
	};
}());