window.paramsToObj = function (s) {
	s = s.replace(/^\?/, '');
	
	return JSON.parse('{"' + s.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
		return key === '' ? value : decodeURIComponent(value);
	});
};

window.objToParams = function (o) {
	var prop;
	var s = '?';

	for (prop in o) {
		if (o.hasOwnProperty(prop)) {
			s += encodeURIComponent(prop) + '=' + encodeURIComponent(o[prop]) + '&';
		}
	}

	return s.replace(/&$/, '');
};

window.getCleanLocation = function () {
	return window.location.href.replace(/\?.*$/, '');
};