AT.utils = AT.utils || {};

AT.utils.debounce = function (fn, delay) {
	var timer = null;

	return function () {
		var context = this;
		var args = arguments;

		clearTimeout(timer);

		timer = setTimeout(function () {
			fn.apply(context, args);
		}, delay);
	};
};

AT.utils.throttle = function (fn, threshhold, scope) {
	var last;
	var deferTimer;

	threshhold = threshhold || 250;

	return function () {
		var context = scope || this;
		var now = 0 + new Date();
		var args = arguments;

		if (last && now < last + threshhold) {
			clearTimeout(deferTimer);
			deferTimer = setTimeout(function () {
				last = now;
				fn.apply(context, args);
			}, threshhold);
		} else {
			last = now;
			fn.apply(context, args);
		}
	};
};