(function () {
	AT.utils.svg4everybody.init();

    AT.components.menu.init();

	AT.components.featureList.init();

	AT.components.blogList.init();

	AT.components.upcomingPerformances.init();

	AT.components.donateBar.init();

    AT.components.donors.init();

    AT.components.search.init();

	$('.parallax').parallax({
		// debug: true,
		interval: 150,
		percent: 0.95
	});

	$('.highlights--list--item, .events--date--event').parallax({
		interval: 150,
		nowrap: true,
		percent: 0.95
	}); // .donors--benefits--item?

    $(document).foundation();

    setTimeout(function () {
        AT.utils.tnew.init();
    }, 1000);
}());