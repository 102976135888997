AT.components.featureList = (function () {
	var featureLists = [];

	function init() {
		$('.feature-list').not('.init').each(function () {
			var _id = 'feature-list-' + window.guid();
			var f;
			var $this = $(this);
			var $features = $this.find('.feature-list--item');
			var $filters = $this.find('.feature-list--filters--filter');
			var $resetButton = $this.find('.feature-list--filters--reset');

			var filters = {};

			$this.attr('id', _id).attr('aria-live', 'polite');

			f = {
				$featuredList: $this,
				$features: $features,
				$filters: $filters,
				$resetButton: $resetButton,
				filters: filters
			};

			$filters.each(function () {
				var $filter = $(this);
				var _p = $filter.data('filter');

				f.filters[_p] = null;

				$filter.attr('aria-controls', _id).on('change', function () {
					_filter(f, _p, $(this).val(), true);
				});
			});

			$resetButton.attr('aria-controls', _id).on('click', function (e) {
				e.preventDefault();

				for (_p in f.filters) {
					if (f.filters.hasOwnProperty(_p)) {
						f.filters[_p] = null;
					}
				}

				_filter(f, null, null, true);
			});

			_checkURL(f);

			$(window).on('popstate', function () {
				_checkURL(f);
			});

			featureLists.push(f);
		});
	}

	function _checkURL(f) {
		var _href = window.location.search;
		var _params = !!_href ? window.paramsToObj(_href) : {};
		var _p = null;

		for (_p in f.filters) {
			if (f.filters.hasOwnProperty(_p)) {

				if (!_params['filter-' + _p]) {
					_params['filter-' + _p] = null;
				}

				f.filters[_p] = _params['filter-' + _p];
			}
		}

		_filter(f, null, null, false);
	}

	function _setURL(f) {
		var _p = null;
		var _href = window.location.search;
		var _params = !!_href ? window.paramsToObj(_href) : {};

		for (_p in f.filters) {
			if (f.filters.hasOwnProperty(_p)) {
				_params['filter-' + _p] = (!!f.filters[_p] ? f.filters[_p] : '');

			}
		}

		window.history.pushState(null, null, window.getCleanLocation() + window.objToParams(_params));
	}

	function _setFilters(f) {
		f.$filters.each(function () {
			var $filter = $(this);

			$filter.val(f.filters[$filter.data('filter')]);
		});

		// I don't really like using "parent" here, but I don't have a great way of removing the extra space on mobile;
		f.$resetButton.parent().toggleClass('hide', _filtersEmpty(f));

		setTimeout(function () {
			$(window).trigger('scroll');
		}, 10);
	}

	function _filter(f, which, value, _push) {
		if (!!which) {
			f.filters[which] = value;
		}

		f.$features.each(function () {
			var $this = $(this);
			var _data = {};
			var _hide = false;
			var _p = null;

			for (_p in f.filters) {
				if (f.filters.hasOwnProperty(_p)) {
					_data[_p] = $this.data(_p);

                    if (!!f.filters[_p] && !_filterCompare(_data[_p], f.filters[_p])) {
						_hide = true;
					}
				}
			}

			$this.toggleClass('hide', _hide);
		}).removeClass('parallax-on parallax-was-on parallax-above').addClass('parallax-off parallax-below');

		if (_push) {
			_setURL(f);
		}

        function _filterCompare(itemData, filterData) {
            var isEqual = false;
            itemData = itemData.split(',');
            itemData.forEach(function (v, i) {
                    if (filterData == v) {
                        isEqual = true;
                    }
                });
            return isEqual;
        }

		_setFilters(f);
	}

	function _filtersEmpty(f) {
		var _isEmpty = true;

		for (_p in f.filters) {
			if (f.filters.hasOwnProperty(_p) && f.filters[_p]) {
				_isEmpty = false;
				break;
			}
		}

		return _isEmpty;
	}

	return {
		init: init
	};
}());