AT.components.upcomingPerformances = (function () {

	function init() {
		$('.upcoming-performances').each(function () {
			var $this = $(this);
			var _id = 'upcoming-performances-' + window.guid();
			var $button = $this.find('.upcoming-performances--load-more');
			var show = $this.data('show') || 8;
			var $performances = $this.find('.events--date--event');

			$this.attr('id', _id).attr('aria-live', 'polite');

			$performances.each(function (i, el) {
				$(el).toggleClass('hide', i >= show);
			});

			$button.attr('aria-controls', _id).on('click', function (e) {
				e.preventDefault();

				$performances.filter('.hide')
					.removeClass('parallax-on parallax-was-on parallax-above')
					.addClass('parallax-off parallax-below')
					.removeClass('hide');

				$button.parentsUntil('.events--date').addClass('hide');

				$(window).trigger('scroll');
			});
		});
	}

	return {
		init: init
	};
}());