AT.components.menu = (function () {
	function init() {
		var $menuBtn = $('.menu-btn');
		var $menu = $('.header--menu');
		var $closeBtn = $menu.find('.close-btn');
		var $headers = $menu.find('.header--menu--sections--item--heading');

		$menuBtn.on('click', function (e) {
			e.preventDefault();
			$menu.addClass('open');
		});

		$closeBtn.on('click', function (e) {
			e.preventDefault();
			$menu.removeClass('open');
		});
		
		$headers.on('click', function (e) {
			e.preventDefault();
			$(this).closest('.header--menu--sections--item').toggleClass('open');
		});
	}

	return {
		init: init
	};
}());